<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{ title }} coupon code</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  {{ title }} coupon code
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="saveCouponCode()">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    placeholder="Coupon name"
                    v-model="coupon.title"
                    outlined
                    :error="$v.coupon.title.$error"
                    dense
                  >
                    <template v-slot:label>
                      Coupon name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.title.$error"
                    >This information is required</span
                  >
                  <span class="text-danger" v-if="errors.title">{{
                    errors.title[0]
                  }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    placeholder="Discount type"
                    label="Discount type"
                    :items="types"
                    :error="$v.coupon.coupon_type.$error"
                    item-text="name"
                    clearable
                    item-value="value"
                    outlined
                    dense
                    v-model="coupon.coupon_type"
                  >
                    <template v-slot:label>
                      Discount type <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.coupon.coupon_type.$error"
                    >This information is required</span
                  >
                  <span class="text-danger" v-if="errors.coupon_type">{{
                    errors.coupon_type[0]
                  }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    outlined
                    :error="$v.coupon.coupon_value.$error"
                    dense
                    v-model="coupon.coupon_value"
                  >
                    <template v-slot:label>
                      Discount value <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.coupon_value.$error"
                    >This information is required</span
                  >
                  <span class="text-danger" v-if="errors.coupon_value">{{
                    errors.coupon_value[0]
                  }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    outlined
                    :error="$v.coupon.offered_coupon_qty.$error"
                    dense
                    type="number"
                    v-model="coupon.offered_coupon_qty"
                  >
                    <template v-slot:label>
                      Coupon quantity <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.offered_coupon_qty.$error"
                    >This information should be number</span
                  >
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-menu
                    ref="menu"
                    v-model="expiry_date_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="coupon.expiry_date"
                        prepend-icon=""
                        prepend-inner-icon="mdi-calendar"
                        label="Expiry date"
                        readonly
                        :error="$v.coupon.expiry_date.$error"
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      >
                        <template v-slot:label>
                          Expiry date <span class="text-danger">*</span>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      v-model="coupon.expiry_date"
                      :active-picker.sync="activePicker"
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                  <span class="text-danger" v-if="$v.coupon.expiry_date.$error"
                    >This information is required</span
                  >
                  <span class="text-danger" v-if="errors.expiry_date">{{
                    errors.expiry_date[0]
                  }}</span>
                </v-col>
                <v-col cols="12" sm="12" md="4" v-if="currentUser.access_type != 'score'">
                  <v-select
                    label="SCORE*"
                    outlined
                    dense
                    item-text="name"
                    :items="scores"
                    item-value="id"
                    :error="$v.coupon.score_id.$error"
                    v-model="coupon.score_id"
                  >
                    <template v-slot:label> SCORE </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.coupon.score_id.$error"
                    >Select Score</span
                  >
                </v-col>

                
                <v-col cols="12" sm="12" md="4">
                  <label>
                    Apply to specific products
                  </label>
                  <v-switch
                    @change="productToggle()"
                    v-model="coupon.applies_to_product"
                  ></v-switch>
                  <!-- <v-select
                    label=""
                    :items="apply_option"
                    item-text="name"
                    item-value="value"
                    @change="productToggle()"
                    outlined
                    dense
                    v-model="coupon.applies_to_product"
                  >
                  </v-select> -->
                </v-col>

                <v-col cols="12" sm="12" md="8" v-if="show.productShow">
                  <v-chip
                    class="ma-2"
                    close
                    @click:close="removeProduct(index)"
                    color="primary"
                    v-if="productList.length > 0"
                    v-for="(product, index) in productList"
                    :key="index"
                    label
                  >
                    {{ product.name_with_subject_code }}
                  </v-chip>
                  <v-autocomplete
                    @change="getProduct()"
                    item-text="name_with_subject_code"
                    class="ma-2"
                    item-value="id"
                    :error="$v.productList.$error"
                    placeholder="Start searching by name or subject code"
                    v-model="selectProducts"
                    :items="products"
                    :search-input.sync="productSearch"
                    return-object
                    outlined
                    dense
                    label="Select products"
                    clearable
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name_with_subject_code }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name_with_subject_code"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select products <span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.productList.$error">Exam</span>
                </v-col>
                <v-col v-else cols="12" md="8"></v-col>
                  

                
                <v-col cols="12" sm="12" md="4">
                  <label>
                    Apply to specific categories
                  </label>
                  <v-switch
                    @change="categoryToggle()"
                    v-model="coupon.applies_to_category"
                  ></v-switch>
                  <!-- <v-select
                    label="Apply to specific categories"
                    :items="apply_option"
                    item-text="name"
                    item-value="value"
                    @change="categoryToggle()"
                    outlined
                    dense
                    v-model="coupon.applies_to_category"
                  >
                  </v-select> -->
                </v-col>
                <v-col cols="12" sm="12" md="8" v-if="show.categoryShow">
                  <v-chip
                    class="ma-2"
                    close
                    @click:close="removeCategory(index)"
                    color="primary"
                    v-if="categoryList.length > 0"
                    v-for="(category, index) in categoryList"
                    :key="index"
                    label
                  >
                    {{ category.name }}
                  </v-chip>
                  <v-autocomplete
                    @change="getCategories()"
                    item-text="name"
                    class="ma-2"
                    item-value="id"
                    :error="$v.categoryList.$error"
                    v-model="selectCategories"
                    :items="categories"
                    :search-input.sync="categorySearch"
                    return-object
                    outlined
                    dense
                    label="Select categories"
                    placeholder="Start searching by name"
                    clearable
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select categories <span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.categoryList.$error"
                    >Select categories</span
                  >
                </v-col>
                <v-col v-else cols="12" md="8"></v-col>
                
                <v-col cols="12" sm="12" md="4">
                  <label>
                    Apply to specific families
                  </label>
                  <v-switch
                    @change="instrumentToggle()"
                    v-model="coupon.applies_to_instrument"
                  ></v-switch>
                  <!-- <v-select
                    label="Apply to specific instrument"
                    :items="apply_option"
                    item-text="name"
                    item-value="value"
                    @change="instrumentToggle()"
                    outlined
                    dense
                    v-model="coupon.applies_to_instrument"
                  >
                  </v-select> -->
                </v-col>
                <v-col cols="12" sm="12" md="8" v-if="show.instrumentShow">
                  <v-chip
                    class="ma-2"
                    close
                    @click:close="removeInstrument(index)"
                    color="primary"
                    v-if="instrumentList.length > 0"
                    v-for="(item, index) in instrumentList"
                    :key="index"
                    label
                  >
                    {{ item.name }}
                  </v-chip>
                  <v-autocomplete
                    @change="getInstruments()"
                    item-text="name"
                    class="ma-2"
                    item-value="id"
                    :error="$v.instrumentList.$error"
                    placeholder="Start searching by name"
                    v-model="selectInstruments"
                    :items="instruments"
                    :search-input.sync="instrumentSearch"
                    return-object
                    outlined
                    dense
                    label="Instrument"
                    clearable
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select instrument families<span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.instrumentList.$error"
                    >Select instrument families</span
                  >
                </v-col>
                <v-col v-else cols="12" md="8"></v-col>

                <v-col cols="12" sm="12" md="4">
                  <label>
                    Apply to specific syllabuses
                  </label>
                  <v-switch
                    @change="syllabusToggle()"
                    v-model="coupon.applies_to_syllabus"
                  ></v-switch>
                  <!-- <v-select
                    label="Apply to specific syllabus"
                    :items="apply_option"
                    item-text="name"
                    item-value="value"
                    @change="syllabusToggle()"
                    outlined
                    dense
                    v-model="coupon.applies_to_syllabus"
                  >
                  </v-select> -->
                </v-col>
                <v-col cols="12" sm="12" md="8" v-if="show.syllabusShow">
                  <v-chip
                    class="ma-2"
                    close
                    @click:close="removeSyllabus(index)"
                    color="primary"
                    v-if="syllabusList.length > 0"
                    v-for="(item, index) in syllabusList"
                    :key="index"
                    label
                  >
                    {{ item.name }}
                  </v-chip>
                  <v-autocomplete
                    @change="getSyllabuses()"
                    :error="$v.syllabusList.$error"
                    item-text="name"
                    class="ma-2"
                    item-value="id"
                    v-model="selectSyllabus"
                    :items="syllabuses"
                    :search-input.sync="syllabusSearch"
                    return-object
                    outlined
                    dense
                    label="Select syllabuses"
                    clearable
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select syllabuses <span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.syllabusList.$error"
                    >Select syllabuses</span
                  >
                </v-col>
                <v-col v-else cols="12" md="8"></v-col>
                  
                
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="coupon.applies_for_min_cart_amount"
                    :items="apply_option"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    @change="cartAmountToggle"
                    label="Minimum cart amount required"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="show.cartAmountShow">
                  <v-text-field
                    outlined
                    dense
                    :error="$v.coupon.min_cart_amount.$error"
                    v-model="coupon.min_cart_amount"
                    label="Minimum Cart Amount"
                  >
                    <template v-slot:label>
                      Minimum cart amount <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.coupon.min_cart_amount.$error"
                    >This information is required</span
                  >
                </v-col>
                  
                <v-col cols="12" sm="12" md="6"> </v-col>

                <v-col cols="12">
                  <span class="font-weight-medium"
                    >Limit to one per enroller</span
                  >
                  <v-switch
                    v-model="coupon.is_one_time_use"
                    :label="coupon.is_one_time_use ? 'Yes' : 'No'"
                    @change="bulkToggle"
                  ></v-switch>
                </v-col>

                <v-col
                  v-bind:md="
                    coupon.is_bulk_coupon == true && show.cartAmountShow == true ? 3 : 3
                  "
                  v-if="couponId == null"
                >
                  <span class="font-weight-medium">Generate bulk unique codes</span>
                  <v-switch
                    v-model="coupon.is_bulk_coupon"
                    :label="coupon.is_bulk_coupon ? 'Enabled' : 'Disabled'"
                    @change="bulkToggle"
                  ></v-switch>
                </v-col>
                <v-col cols="9">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      v-if="couponId == null && show.bulkShow"
                    >
                        <div class="row">
                          <div class="col-md-9">
                            <v-text-field
                              label="Bulk coupon prefix"
                              outlined
                              @input="countCouponPrefixLength"
                              dense
                              :error="$v.coupon.bulk_coupon_prefix.$error"
                              v-model="coupon.bulk_coupon_prefix"
                            >
                              <template v-slot:label>
                                Bulk Coupon Prefix <span class="text-danger">*</span>
                              </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.coupon.bulk_coupon_prefix.$error">This information is required</span>
                          </div>
                          <div class="col-md-3">
                            <i class="fa fa-info-circle mt-3 cursor-pointer" aria-hidden="true" @click="showCouponPrompt('coupon-prefix')"></i>
                          </div>
                        </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      v-if="couponId == null && show.bulkShow"
                    >
                      <div class="row">
                        <div class="col-md-9">
                          <v-text-field
                            @blur="countCouponPrefixLength"
                            label="Bulk coupon length"
                            outlined
                            :error="$v.coupon.bulk_coupon_length.$error || errorMessage"
                            dense
                            v-model="coupon.bulk_coupon_length"
                          >
                            <template v-slot:label>
                              Bulk Coupon length <span class="text-danger">*</span>
                            </template>
                          </v-text-field>
                          <span class="text-danger" v-if="$v.coupon.bulk_coupon_length.$error"
                          >This information is required</span>
                          <span class="text-danger" v-if="errorMessage">{{
                            errorMessage
                          }}</span>
                        </div>
                        <div class="col-md-3">
                          <i class="fa fa-info-circle mt-3 cursor-pointer" aria-hidden="true" @click="showCouponPrompt('coupon-length')"></i>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" v-if="showCouponCodeField">
                      <v-text-field
                        label="Coupon code*"
                        outlined
                        dense
                        :error="$v.coupon.coupon_code.$error"
                        v-model="coupon.coupon_code"
                      >
                        <template v-slot:label>
                          Coupon code <span class="text-danger">*</span>
                        </template>
                      </v-text-field>
                      <span class="text-danger" v-if="$v.coupon.coupon_code.$error"
                        >This information is required</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label>Description</label>
                  <ckeditor v-model="coupon.description" :config="editorConfig">
                  </ckeditor>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <span class="font-weight-medium">Status</span>
                  <v-switch
                    v-model="coupon.is_active"
                    label="Status"
                    :label="coupon.is_active ? 'Active' : 'Inactive'"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="text-right">
                  <v-btn text x-large @click="back"> Cancel </v-btn>

                  <v-btn dark x-large @click="saveCouponCode()" :loading="loading">
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </div>
        </div>
      </div>
    </div>
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="600"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-row class="mt-2">
                <v-col cols="12" sm="6" md="12">
                  <h4>{{ promptMessage }}</h4>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
  </v-app>
</template>
<script>
import ScoreService from "@/services/score/score/ScoreService";
import CouponCodeService from "@/services/couponCode/CouponCodeService";
import CategoryService from "@/services/product/category/CategoryService";
import { required, requiredIf, numeric } from "vuelidate/lib/validators";
import ProductService from "@/services/product/ProductService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";

const syllabus = new SyllabusService();
const instrument = new InstrumentService();
const product = new ProductService();
const category = new CategoryService();
const coupon = new CouponCodeService();
const score = new ScoreService();
export default {
  validations: {
    coupon: {
      title: { required },
      coupon_type: { required },
      expiry_date: { required },
      coupon_value: { required },
      offered_coupon_qty: { required },
      score_id: {
        required: requiredIf(function (nestedModel) {
          return this.currentUser.access_type == "score";
        }),
      },

      bulk_coupon_prefix: {
        required: requiredIf(function (nestedModel) {
          return this.show.bulkShow == true && this.couponId == null;
        }),
      },
      bulk_coupon_length: {
        required: requiredIf(function (nestedModel) {
          return this.show.bulkShow == true && this.couponId == null;
        }),
      },
      min_cart_amount: {
        required: requiredIf(function (nestedModel) {
          return this.show.cartAmountShow == true;
        }),
      },
      coupon_code: {
        required: requiredIf(function (nestedModel) {
          return this.show.bulkShow == false && this.couponId == null;
        }),
      },
    },
    categoryList: {
      required: requiredIf(function (nestedModel) {
        return this.show.categoryShow == true;
      }),
    },
    productList: {
      required: requiredIf(function (nestedModel) {
        return this.show.productShow == true;
      }),
    },
    instrumentList: {
      required: requiredIf(function (nestedModel) {
        return this.show.instrumentShow == true;
      }),
    },
    syllabusList: {
      required: requiredIf(function (nestedModel) {
        return this.show.syllabusShow == true;
      }),
    },
  },
  data() {
    return {
      title: "",
      expiry_date_menu: false,
      categories: [],
      products: [],
      instruments: [],
      syllabuses: [],
      activePicker: null,
      scores: [],
      errors: [],
      coupon: {
        title: "",
        expiry_date: "",
        coupon_type: "",
        coupon_value: "",
        offered_coupon_qty: "",
        description: "",
        coupon_code: "",
        bulk_coupon_prefix: "",
        bulk_coupon_length: "",

        min_cart_amount: "",
        applies_to_category: false,
        applies_to_product: false,
        applies_to_syllabus: false,
        applies_to_instrument: false,
        applicable_categories: [],
        applicable_products: [],
        applicable_instruments: [],
        applicable_syllabuses: [],
        applies_for_min_cart_amount: false,
        is_bulk_coupon: false,
        is_one_time_use: false,
        is_active: true,
      },
      loading: false,
      types: [
        { name: "Fixed Price", value: "fixed" },
        { name: "Percentage", value: "percentage" },
      ],
      apply_option: [
        { name: "Yes", value: 1 },
        { name: "No", value: 0 },
      ],
      show: {
        categoryShow: false,
        productShow: false,
        syllabusShow: false,
        instrumentShow: false,
        cartAmountShow: false,
        bulkShow: false,
      },
      currentUser: {},
      editorConfig: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
        versionCheck: false
      },
      selectCategories: {},
      selectProducts: {},
      selectInstruments: {},
      selectSyllabus: {},
      productSearch: "",
      instrumentSearch: "",
      syllabusSearch: "",
      categorySearch: "",
      productList: [],
      categoryList: [],
      syllabusList: [],
      instrumentList: [],
      errorMessage: "",
      showCouponCodeField: false,
      promptMessage: '',
      dialog: false
    };
  },
  methods: {
    back() {
      this.$tabs.close();
      this.$router.push({
        name: "coupon-code",
      });
    },
    closeDialog(){
      this.dialog = false;
    },
    getCategories() {
      let categoryExist = this.categoryList.some(
        (category) => category.id === this.selectCategories.id
      );
      if (!categoryExist) {
        this.categoryList.push(this.selectCategories);
      }

      this.selectCategories = {};
      this.categorySearch = null;
    },
    getProduct() {
      let productExist = this.productList.some(
        (product) => product.id === this.selectProducts.id
      );
      if (!productExist) {
        this.productList.push(this.selectProducts);
      }
      this.selectProducts = {};
      this.productSearch = null;
    },

    getInstruments() {
      let instrumentExist = this.instrumentList.some(
        (instrument) => instrument.id === this.selectInstruments.id
      );
      if (!instrumentExist) {
        this.instrumentList.push(this.selectInstruments);
      }
      this.selectInstruments = {};
      this.instrumentSearch = null;
    },
    getSyllabuses() {
      let syllabusExist = this.syllabusList.some(
        (syllabus) => syllabus.id === this.selectSyllabus.id
      );
      if (!syllabusExist) {
        this.syllabusList.push(this.selectSyllabus);
      }
      this.selectSyllabus = {};
      this.syllabusSearch = null;
    },
    saveCouponCode() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.categoryList != null) {
          this.coupon.applicable_categories = this.categoryList.map((obj) => obj.id);
          this.coupon.applicable_categories = this.coupon.applicable_categories.toString();
        }
        if (this.productList != null) {
          this.coupon.applicable_products = this.productList.map((obj) => obj.id);
          this.coupon.applicable_products = this.coupon.applicable_products.toString();
        }
        if (this.instrumentList != null) {
          this.coupon.applicable_instruments = this.instrumentList.map((obj) => obj.id);
          this.coupon.applicable_instruments = this.coupon.applicable_instruments.toString();
        }
        if (this.syllabusList != null) {
          this.coupon.applicable_syllabuses = this.syllabusList.map((obj) => obj.id);
          this.coupon.applicable_syllabuses = this.coupon.applicable_syllabuses.toString();
        }
        if (this.couponId != null) {
          /*this.loading=true;*/
          coupon
            .update(this.couponId, this.coupon)
            .then((response) => {
              this.couponCodeDetail();
              this.$snotify.success("Coupon code updated");
              this.loading = false;
            })
            .catch((error) => {
              this.errors = error.response.data.errors;
            });
        } else {
          /* this.loading=true;*/
          coupon
            .create(this.coupon)
            .then((response) => {
              this.$snotify.success("Coupon code added");
              this.back();
              this.coupon = {};
              this.$v.$reset();
              this.loading = false;
            })
            .catch((error) => {
              this.errors = error.errors;
            });
        }
      }
    },
    couponCodeDetail() {
      if (this.couponId != null || this.couponId != undefined) {
        this.title = "Update";
        coupon.show(this.couponId).then((response) => {
          this.coupon = response.data.couponCode;
          this.productToggle();
          this.instrumentToggle();
          this.syllabusToggle();
          if (this.coupon.applicable_categories_list) {
            this.categoryList = this.coupon.applicable_categories_list;
          } else {
            this.categoryList = [];
          }
          if (this.coupon.applicable_instruments_list) {
            this.instrumentList = this.coupon.applicable_instruments_list;
          } else {
            this.instrumentList = [];
          }
          if (this.coupon.applicable_syllabuses_list) {
            this.syllabusList = this.coupon.applicable_syllabuses_list;
          } else {
            this.syllabusList = [];
          }
          if (this.coupon.applicable_products_list) {
            this.productList = this.coupon.applicable_products_list;
          } else {
            this.productList = [];
          }

          this.categoryToggle();
          this.bulkToggle();
          this.cartAmountToggle();
        });
      } else {
        this.title = "Create";
        this.coupon = {
          title: "",
          expiry_date: "",
          coupon_type: "",
          coupon_value: "",
          offered_coupon_qty: "",
          description: "",
          coupon_code: "",
          bulk_coupon_prefix: "",
          bulk_coupon_length: "",
          min_cart_amount: "",
          applies_to_category: false,
          applies_to_product: false,
          applies_to_syllabus: false,
          applies_to_instrument: false,
          applicable_categories: [],
          applicable_products: [],
          applicable_instruments: [],
          applicable_syllabuses: [],
          applies_for_min_cart_amount: false,
          is_bulk_coupon: false,
          is_active: true,
        };
        this.showCouponCodeField = true;
      }
    },
    getAllScore() {
      score
        .all()
        .then((response) => {
          this.scores = response.data.scores;
        })
        .catch((err) => {});
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    categoryToggle() {
      if (this.coupon.applies_to_category == true) this.show.categoryShow = true;
      else this.show.categoryShow = false;
      this.$v.$reset();
    },
    productToggle() {
      if (this.coupon.applies_to_product == true) this.show.productShow = true;
      else this.show.productShow = false;
      this.$v.$reset();
    },
    instrumentToggle() {
      if (this.coupon.applies_to_instrument == true) this.show.instrumentShow = true;
      else this.show.instrumentShow = false;
      this.$v.$reset();
    },
    syllabusToggle() {
      if (this.coupon.applies_to_syllabus == true) this.show.syllabusShow = true;
      else this.show.syllabusShow = false;
      this.$v.$reset();
    },
    cartAmountToggle() {
      if (this.coupon.applies_for_min_cart_amount == true)
        this.show.cartAmountShow = true;
      else this.show.cartAmountShow = false;
      this.$v.$reset();
    },
    bulkToggle() {
      if (this.coupon.is_bulk_coupon == true) {
        this.show.bulkShow = true;
        this.showCouponCodeField = false;
      } else {
        this.show.bulkShow = false;
        if (this.coupon.details[0].is_used == 0) {
          this.showCouponCodeField = true;
        } else {
          this.showCouponCodeField = false;
        }
      }
      this.$v.$reset();
    },
    removeCategory(index) {
      this.categoryList.splice(index, 1);
    },
    removeProduct(index) {
      this.productList.splice(index, 1);
    },
    removeInstrument(index) {
      this.instrumentList.splice(index, 1);
    },
    removeSyllabus(index) {
      this.syllabusList.splice(index, 1);
    },
    countCouponPrefixLength() {
      let bulk_coupon_prefix_length = parseInt(this.coupon.bulk_coupon_prefix.length) + 1;

      if (
        this.coupon.bulk_coupon_length &&
        parseInt(this.coupon.bulk_coupon_length) < parseInt(bulk_coupon_prefix_length)
      ) {
        this.coupon.bulk_coupon_length = null;
        this.coupon.bulk_coupon_length = bulk_coupon_prefix_length;
        this.errorMessage =
          "Bulk Coupon Length Field must be greater than Bulk Coupon Prefix";
        setTimeout(() => {
          this.errorMessage = null;
        }, 3000);
      }
      if (!this.coupon.bulk_coupon_length) {
        this.coupon.bulk_coupon_length = bulk_coupon_prefix_length;
      }
      if (!this.coupon.bulk_coupon_prefix) {
        this.coupon.bulk_coupon_length = null;
        this.coupon.bulk_coupon_prefix = null;
      }
    },
    showCouponPrompt(value){
      if(value == 'coupon-prefix'){
        this.promptMessage = 'Prefix will be used to easily to readable and unique coupon codes.';
        this.dialog = true;
      }
      if(value == 'coupon-length'){
        this.promptMessage = 'Provide total number of coupons to generate. E.g. 173';
        this.dialog = true;
      }
    }
  },
  mounted() {
    this.getCurrentUser();
    this.couponCodeDetail();
    this.getAllScore();
  },
  watch: {
    productSearch(val) {
      product
        .search(val)
        .then((response) => {
          this.products = response.data.products;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    syllabusSearch(val) {
      syllabus
        .search(val)
        .then((response) => {
          this.syllabuses = response.data.syllabuses;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    instrumentSearch(val) {
      instrument
        .search(val)
        .then((response) => {
          this.instruments = response.data.instruments;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    categorySearch(val) {
      category
        .search(val)
        .then((response) => {
          this.categories = response.data.categories;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    couponId() {
      return this.$route.params.couponId;
    },
  },
};
</script>
