import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

    export default class ProductService
    {
        #api = null;
        constructor() {
            this.#api = API_URL + 'admin/product';
        }
        paginate(data={},index = null) {
            let url = `${this.#api}`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);
        }
        dedicatedPrice(data={},index = null)
        {
            let url = `${this.#api}/dedicated-price`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);
        }

        getLessons(data={},index = null, productId) {
            let url = `${this.#api}/${productId}/lesson`;
            if (data != null && data != undefined && data != "") {
                var queryString = Object.keys(data).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }).join('&');
                url = url + '?' + queryString;

            }
            if (queryString)
                url = url + '&page=' + index;
            else
                url = url + '?page=' + index;
            return apiService.get(url);
        }

        all() {
            let url = `${this.#api}/get/all`;
            return apiService.get(url)
        }

        getCoRequisiteProduct(productId){
            let url = `${this.#api}/${productId}/get-corequisite-products`;
            return apiService.get(url)
        }

        show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url)
        }
        
        create(data) {
            let url = `${this.#api}`
            return apiService.post(url, data)
        }

        update(id,data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url,data)
        }
        
        delete(examOptionId) {
            let url = `${this.#api}/${examOptionId}`
            return apiService.delete(url)
        }

        search(data={}){
            let url = `${this.#api}/search/all`;
            let param = {params: data};
            return apiService.query(url, param);
        }

        searchPracticalExam(data={}){
            let url = `${this.#api}/search/practical-exams`;
            let param = {params: data};
            return apiService.query(url, param);
        }

        searchByType(data={}){
            let url = `${this.#api}/search/by/type`;
            let param = {params: data};
            return apiService.query(url, param);
        }
        searchByComplimentaryType(data={}){
            let url = `${this.#api}/complimentary/search/by/type`;
            let param = {params: data};
            return apiService.query(url, param);
        }

        importPrice(data) {
            let url = `${this.#api}/import/csv`;
            return apiService.post(url, data);
        }

        exportProduct(data){
            let url = `${this.#api}/export/csv`;
            if (data != null && data != "") {
                let queryString = Object.keys(data).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }).join('&');
                url = url + '?' + queryString;

            }
            window.open(url, "_blank");
        }
        updateProductPrice(data){
            let url = `${this.#api}/dedicated-price/update`
            return apiService.post(url,data)
        }
        findProductDetail(data={}){
            let url = `${this.#api}/find/detail`;

            let param = {
                params: data
            }
            return apiService.query(url,param);
        }
        searchBySubjectCode(data={}){
            let url = `${this.#api}/search/subject-code`;

            let param = {
                params: data
            }
            return apiService.query(url,param);
        }
        getProductPriceByScore(data={}){
            let url = `${this.#api}/get/price-by-score`;

            let param = {
                params: data
            }
            return apiService.query(url,param);
        }
    }
