import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ScoreService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/score';
    }

    all(data={}) {
        let url = `${this.#api}/get/all`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getScores() {
        let url = `${this.#api}/get/score-by/practice-exam`
        return apiService.get(url)
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.post(url, data)
    }
    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    deleteScoreLogo(id){
        let url = `${this.#api}/${id}/logo`
        return apiService.delete(url);
    }
    deleteCertificateSignatureImage(id){
        let url = `${this.#api}/${id}/certificate-signature-image`
        return apiService.delete(url);
    }
    deleteMap(id){
        let url = `${this.#api}/${id}/map`
        return apiService.delete(url);
    }
}