import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SyllabusService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/syllabus';
    }

    all() {
        let url = `${this.#api}/get/all`;
        return apiService.get(url)
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    update(id,data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url,data)
    }
    
    delete(syllabusId) {
        let url = `${this.#api}/${syllabusId}`
        return apiService.delete(url)
    }

    getByInstrument(instrumentId){
        let url = `${this.#api}/${instrumentId}/get/by/instrument`;
        return apiService.get(url)
    }
    
    sort(data=[]){
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }
    search(data={}){
        let url = `${this.#api}/search/all`;
        let param = {params: data};
        return apiService.query(url, param);
    }
    getFilterData(data={}){
        let url = `${this.#api}/filter/all`;

        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getByScore(scoreId){
        let url = `${this.#api}/${scoreId}/get/by/score`;
        return apiService.get(url)
    }
    getByExamSession(examSessionId, data){
        let url = `${this.#api}/${examSessionId}/get/by/exam-session?instrument_ids=${data}`;
        return apiService.get(url);
    }
}